import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './UsersPage.css';
import { createRoot } from 'react-dom';
import LoginSU from './LoginSU';

function menu() {
const root = createRoot(document.getElementById("root"));

  root.render(<LoginSU />);
}

const UsersPage = () => {
  const root = createRoot(document.getElementById("root"));
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(6);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleSearchInputChange = (event) => 
  {
    setSearchTerm(event.target.value);
  };

  function deleteUse(username) {
    fetch(`https://rote.bittstecnologia.dev.br/del/${username}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) { // Verifica se a resposta tem status de erro
          throw new Error('Erro ao excluir o usuário' + username);
        }
        return response.json(); // Extrai os dados da resposta como JSON
      })
      .then(data => {
        alert(`Usuário ${data.username} excluído com sucesso!`);
        const updatedUsers = users.filter(user => user.id !== userIdToDelete);
        setUsers(updatedUsers);
        setShowModal(false);
        root.render(<UsersPage />);

      })
      .catch(error => {
        alert(error.message); // Exibe uma mensagem de erro no alerta
        console.error(error); // Exibe o erro no console para fins de depuração
      });
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get('https://rote.bittstecnologia.dev.br/users');
      setUsers(res.data);
    }
    fetchUsers();
  }, []);

  const deleteUser = async (username) => {
    try {
      await axios.delete(`https://rote.bittstecnologia.dev.br/users/${username}`);
      setUsers(users.filter(user => user.username !== username));
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  }


 
  const renderUsers = currentUsers.map(user => {
    let accessLevel = "";
    if (user.su === 0) 
    {
      accessLevel = "Comum";
    }
    
    else if (user.su === 1) 
    {
      if(user.username !== "bitts")
      {
      accessLevel = "Administrador";
      }
      else{
        accessLevel = "Mestre";
      }
    }

  
    


    let deleteButton = null; // inicialmente, o botão delete não é renderizado
  
    // Verifica se o nome de usuário é "bitts". Se não for, renderiza o botão delete
    if (user.username !== "bitts") {
      deleteButton = (
        <button id='delButton' className="delete-button" onClick={() => { setShowModal(true); setUserIdToDelete(user.username); }}>Excluir</button>
      );
    }
  
    return (
      <div className='division'>
        <div key={user.id} className="user-box">
          <div className='div1'>  
            <p>Usuário: <span className='namebox blue'>{user.username}</span></p>
            <p>Nível de acesso: <span className='namebox red'>{accessLevel}</span></p>
          </div>
          <div className='div2'>
            {deleteButton}
          </div>
        </div>
      </div>
    );
  });
  

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map(number => (
    <button
      className="page-link"
      key={number}
      onClick={() => setCurrentPage(number)}
    >
      {number}
    </button>
  ));

  return (
    <main className="main">
      <div className='TitleBox'>
        <h1 className='title'>Usuários</h1>
      </div>
      <div className="searchInput ">
           <input
              className="searchBox"
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
      </div>
      
      <div className="container">
        {renderUsers}
      </div>
      <div className="pagination-container">
        <ul id="page-numbers" className="pagination">
          {renderPageNumbers}
        </ul>
      </div>

      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        {/* ... */}
      </Modal>
      <div className='btn'>
        <button className='back' onClick={menu}>Voltar</button>
      </div>
    </main>
  );
};

export default UsersPage;