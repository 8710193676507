import React from 'react';
import { createRoot } from 'react-dom';
import FileList from "./FileList";
import Backup from './Backup';
import Login from './Login';
import Cadastro from './Cadastro';
import UploadSuccess from './UploadSuccess;';
import LoginSU from './LoginSU';
import UsersPage from './UsersPage';



const root = createRoot(document.getElementById("root"));


root.render(<Login/>);