import React, { useState, useEffect } from "react";
import logo from "./img/logo.png";
import configLogo from "./img/config.png";
import axios from "axios";
import "./Backup.css";
import FileList from "./FileList.js";
import { createRoot } from 'react-dom';
import Login from "./Login.jsx";

const local = localStorage.getItem("pastaPadrao");

function exit() {
const root = createRoot(document.getElementById("root"));
root.render(<Login />);
}

function Backup() {

const [file, setFile] = useState("");
const [fileName, setFileName] = useState("");
const [progress, setProgress] = useState(0);
const [isUploading, setIsUploading] = useState(false);

const [apiUrl, setApiUrl] = useState("http://localhost:3333");

useEffect(() => {
if (navigator.onLine) {
setApiUrl("https://rote.bittstecnologia.dev.br");
} else {
setApiUrl("http://localhost:3333");
}



const handleConnectionChange = () => {
  if (navigator.onLine) {
    setApiUrl("https://rote.bittstecnologia.dev.br");
  } else {
    setApiUrl("http://localhost:3333");
  }
};

window.addEventListener("online", handleConnectionChange);
window.addEventListener("offline", handleConnectionChange);

return () => {
  window.removeEventListener("online", handleConnectionChange);
  window.removeEventListener("offline", handleConnectionChange);
};
}, []);

const handleFileUpload = (event) => {
const files = event.target.files;
const fileNames = [];



for (let i = 0; i < files.length; i++) {
  fileNames.push(files[i].name);
}

setFile(files);
setFileName(fileNames.join(', '));
};

function renderList() {
const root = createRoot(document.getElementById("root"));
root.render(<FileList />);
}

const handleFileDrop = (event) => {
event.preventDefault();
event.stopPropagation();
setFile(event.dataTransfer.files[0]);
setFileName(event.dataTransfer.files[0].name);
};

const handleFormSubmit = (event) => {
event.preventDefault();
console.log("Arquivo selecionado:", file);
};

const handleBackup = () => {
const username = sessionStorage.getItem('user');
const files = Array.from(file);
const formData = new FormData();



setIsUploading(true);

for (const f of files) {
  formData.append("username", username);
  formData.append("files", f);

  axios
    .post(`${apiUrl}/posts`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progressPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(progressPercent);
      },
    })
    .then((response) => {
      console.log(response);
      setIsUploading(false);
      setProgress(0);
    })
    .catch((error) => {
      console.log(error);
      setIsUploading(false);
      setProgress(0);
    });
}
};

const handleButtonClick = () => {
const path = window.prompt("Digite o local da pasta padrão para o backup automático");
localStorage.setItem("pastaPadrao", path);
document.getElementById("pathtxtarea").value = path;
};
return (
  <div className="backup-container">
    {isUploading && (
      <div className="backup-loading">
        <div className="backup-loading-spinner"></div>
      </div>
    )}
    <div className={isUploading ? "backup-content backup-disabled" : "backup-content"}>
      <div className="backup-content">

            

        <img className="confLogo" src={configLogo} onClick={() => {
          {
            const confElem = document.getElementById("conf");
            confElem.hidden = !confElem.hidden; // alternar o estado de visibilidade
          }
        }} />

        <div className="configLogo">
          <div id="conf" hidden>
            <h4 className="pathText">Pasta padrão:</h4>

            <input disabled type="text" id="pathtxtarea" className="pathtxtarea" value={local}>

            </input>
            <button className="pbutton" onClick={handleButtonClick}
            >...</button>

            <button
              onClick={ ""}
            >Up</button>

          </div>
        </div>
        <img src={logo} alt="Bitts Backup" className="backup-logo" />

        <h2 className="backup-title">Faça o backup do seu arquivo agora</h2>
        <form onSubmit={handleFormSubmit} className="backup-form">
          <div className="backup-input-container">
            <label htmlFor="backup-input" className="backup-input-label">
              Selecione um arquivo:
            </label>

            <input
              id="backup-input"
              type="file"
              multiple
              onChange={handleFileUpload}
              className="backup-input"
              directory
              webkitdirectory
            />
          </div>
      
   
          <button
            type="submit"
            className="backup-button"
            onClick={handleBackup}
            disabled={isUploading}
          >
            {isUploading ? "Fazendo upload..." : "Realizar Backup"}
          </button>
          {isUploading && (
            <div className="backup-progress-container">
              <div
                className="backup-progress-bar"
                style={{ width: `${progress}%` }}
              ></div>
              <span className="backup-progress-text">{`${progress}%`}</span>
            </div>
          )}
          <div>
            <br></br>
            <br></br>
          </div>

          <button
            type="submit"
            className="backup-button"
            onClick={renderList}
            disabled={isUploading}
          >
            {isUploading ? "Ver arquivos" : "Ver arquivos"}
          </button>
          <div>
          </div>

          {isUploading && (
            <div className="backup-progress-container">
              <div
                className="backup-progress-bar"
                style={{ width: `${progress}%`, backgroundColor: "#0077b6" }}
              ></div>
              <span className="backup-progress-text">{`${progress}%`}</span>
            </div>
          )}
          <br></br>
          <br></br>

          <div className="exitButtonDiv">
            <button onClick={exit} className="backB">
              Sair
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Backup;

