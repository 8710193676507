import React from 'react';
import './LoginSU.css';
import Logo from './logo.jpeg';
import Login from './Login';
import Backup from './Backup';
import { createRoot } from 'react-dom';
import Cadastro from './Cadastro';
import UsersPage from './UsersPage';



function LoginSU() {
const root = createRoot(document.getElementById('root'));

function loadCad()
{  
   root.render(<Cadastro/>);
   
}

function login() 
{
  root.render(<Login/>);
}

function edit()
{
 
root.render(<UsersPage/>);
}

function bkp()
{
 

root.render(<Backup/>);
}
  const username = sessionStorage.getItem('user');
if (username) {
  const titleElement = document.querySelector('.title');
  titleElement.textContent = `Bem vindo, ${username}!`;
}

  return (
    <div className="body">
      <div className="container">
        <div className="wrap">

          <form className="form">
            <span className="title">Bem vindo, {username}  </span>

            <div className="cadbtn">
              <button onClick={loadCad} className="cadbtn-btn">Cadastrar Usuário</button>
            </div>
            <br></br><br></br>
          <div className="bkbtn">
              <button onClick={bkp} className="bkbtn-btn">Ir para backup</button>
            </div>

            <br></br><br></br>

            <div className="viewbtn">
              <button onClick={edit} className="viewbtn-btn">Ver Usuários Cadastrados</button>
            </div>
            <br></br><br></br>

            <div className="exit">
            <br></br><br></br> 

              <button onClick={login} className="exit-exit">sair</button>
            </div>
          </form>
          <div className="image" style={{backgroundImage: `(${Logo})`}}></div>
        </div>
      </div>
    </div>

  );
  
}




export default LoginSU;
